:root {
  --active: #ffd140;
  --nActive: #ffb628;
  --fff: #ffffff;
  --fff-bg: var(--night-bg2, #ffffff);
  --t1: #d8d8d8;
  --t2: #666666;
  --t3: #b6bdc6;
  --t4: #eeeff0;
  --t5: #cccccc;
  --t6: #101010;
  --t7: #b6bcc7;
  --t8: var(--night-bg1, #ebecf0);
  --t9: var(--night-placeHolder, #b6bcc7);
  --t10: var(--night-font9, #b6bcc7);
  --t11 : #777E90;
  //--r1: #f84960;
  //--f1: #02c076;

  --r1: var(--color-fall);
  --f1: var(--color-raise);

  --f2: #314134;
  --f3: #2bad66;
  --f4: #9fb0c8;
  --f5: var(--night-font1, #333333);
  --f6: #848e9c;
  --f7: #000000;
  --f8: var(--night-line-bg, #f7f7f7);
  --f9: #999999;
  --f10: #a0acbc;
  --f11: #b5b5b5;
  --f12: #1b262d;
  --f13: #353d49;
  --f14: #353945;
  --f15: var(--night-font1, #fff);
  --f16: #B6BCC7;

  --ft: var(--night-ft, rgba(0, 0, 0, 0.04));
  --br: var(--night-bg2, #e8eaef);
  //背景色
  --bg1: var(--night-bg3, #f7f7f7); // 底部背景
  --bg2: var(--night-bg2, #ffffff); // 次级框背景 #166230
  --bg3: var(--night-bg3, #fff); // 头部背景
  --bg12: var(--night-bg12, #fff); //新设计稿背景
  --bg13: var(--night-bg12, #f8f9fc);
  --bg14: var(--night-bg13, #fff);
  --line-bg: var(--night-line-bg, #eeeff2); // 线条背景
  --line-bg2: var(--night-line-kuang, #ebecf0); // 线条背景
  --line-bg3: var(--night-bg3, #ffffff); // 线条背景
  --line-bg4: var(--night-bg13, #eeeff2); // 线条背景
  --line-bg5: var(--night-font10, #eeeff2);
  --line-bg6: var(--night-font9, #b6bcc7);

  --foot-bg: var(--night-foot, #ffffff); // 底部栏背景

  --input-bg: var(--night-inputBg, #eeeeee); // 现货输入框背景
  --input-bg1: var(--night-inputBg, #ffffff); // 现货输入框背景

  --order-bg: var(--night-inputBg, #fef6e3); // 现货输入框背景

  --search-input-bg: var(--night-inputBg, #ebecf0); // 现货输入框背景
  --gray1: var(--night-gray1, #e6e8ec);
  --bg4: #688494;
  --bg5: #5891fa;
  --bg6: var(--night-line-bg, #eeeff2);
  --bg7: #dfeaff;
  --bg9: var(--night-inputBg, #f4f5f7);
  --bg10: var(--night-bg1, #f8f8f8);
  --bg11: var(--night-bg11, #fcf8ef);
  --bg15: var(--night-bg14, #f8f9fc);
  --bg16: var(--night-bg14, #fff);
  --bg17: var(--night-bg14, #eeeff2);
  --bg18: var(--night-bg15, #eeeff2);

  --l1: rgba(88, 113, 144, 0.3);

  --switchBg: var(--night-switchBg, #e9e9eb); //switch背景颜色
  --bar: var(--night-bg13, #eeeff2); //marketBar颜色

  --disBtn-bg: var(--night-dis-btn, #ebecf0); //按钮不可点击

  --night-f8: none;
  --night-bg1: #141416;
  --night-bg2: #1c1e24;
  --night-bg3: #141416;
  --night-bg4: #7F8AA1;
  --night-bg12: #141416;
  --night-bg13: #1e2129;
  --night-bg14: #1C1E24;
  --night-bg15: '#232427';
  --night-foot: #222733;
  --night-font1: #E6E8EC;
  --night-font2: #fff;
  --night-font3: #fff;
  --night-font4: #eee;
  --night-font5: #858E9D;
  --night-font6: #e6e8ec;
  --night-font7: #ffd140;
  --night-font8: #848E9C;
  --night-font9: #777E90;
  --night-font10: #23262F;
  --night-ft: rgba(255; 255; 255; 0.06);
  --night-switchBg: #f3ad00;
  --night-inputBg: #1C1E24;
  --night-line-bg: #23262F;
  --night-line-kuang: #404958;
  --night-bar: #434C5B;
  --night-border1: #353945;
  --night-gray1: rgba(255; 255; 255; 0.1);
  --night-closeColor: #777E90;

  --night-rb: #351C24;
  --night-gb: #172D2A;

  --night-spot-quote-bottom-bg: #13151B;
  --night-sopt-history-title-bg: #00000099;
  --night-deposit-note-bg: #33352C;
  --night-bg11: #33352c;
  --night-dis-btn: #434C5B;
  // '--night-font1': '#101010';
  // '--night-font1': '#101010';
  --night-placeHolder: #353945;
  --night-tabBar: #1E2129;
  --night-changeOptions:#E6E8EC;
  --night-tipsDisplay:#777E90;

  // 字体眼神
  --mainFont: var(--night-font1, #1e2129);
  --mainFont2: var(--night-line-kuang, #ffffff);
  --mainFont3: var(--night-font4, #858e9d);
  --mainFont4: var(--night-font6, #1e2129);
  --mainFont5: var(--night-font6, #777e90);
  --mainFont6: var(--night-font7, #1e2129);
  --mainFont7: var(--night-font6, #1e2129);
  --mainFont8: var(--night-font6, #1c1e24);
  --mainFont9: var(--night-font9, #848e9c);

  --mainFont10: var(--night-font9, #b6bcc7);
  --mainFontActive: var(--night-font1, #1e2129);
  --mainFontTxt: var(--night-font6, #1c1e24);
  --tradePlaceHolder: var(--night-border1, #b6bcc7);
  --tradeClose: var(--night-closeColor, #b6bcc7);

  --headerFont: var(--night-font2, #000); // 头部字体颜色
  --fc2: var(--night-font3, #333); //条目右侧颜色

  //--fc1: #333333; // 字体颜色
  --fc5: var(--night-font9, #b6bcc7);
  --fc6: #f0f0f0;
  --lc1: #f7f7f7; //line color
  --lc2: #eef1fb;
  --lc3: #f7f7f7;
  --lc4: #2f3d45;
  --lc5: #202b3f;
  --lc6: #222d48;

  --rb: var(--night-rb, #feecee);
  --gb: var(--night-gb, #e9f8f2);

  --border1: var(--night-border1, #f2f3f5);
  --border2: var(--night-border1, #b6bcc7);
  --border3: var(--night-border1, #eeeff2);
  --border-light: #F8F9FC;

  --blue: #4677d7;
  --ma5: #4677d7;
  --ma10: #f69c6a;
  --ma30: #cd85ff;

  --btn-active-color: #141416;

  --tab-bar-color: var(--night-tabBar, #EEEFF2);

  --pMFont: var(--font,'PingFangSC,PingFangSC-Medium');
  --pRFont: var(--font2,'PingFangSC,PingFangSC-Regular');
  --pSFont: var(--font3,'PingFangSC,PingFangSC-Semibold');

  --light-primary: #1E2129;
  --light-bg: #F8F9FC;
  --light-secondary: #EEEFF2;

  //预测
  --pbg1: #22252E;
  --pbg2: #121212;
  --pbg3: #1B1E24;
  --pbg4: #272A34;
  --pbg5: #2F3038;
  --pbg6: #777D90;
  --pbg7: #1E2128;

  --pFont1: #777D90;
  --pFont2: #141416;
  --pFont3: #343844;
  --pFont4: #E6E7EC;
  --pFont5: #fff;
  --pFont6: #131316;
  --pFont7: #4E5767;

  --pLine1: #1E2128;
  --pLine2: #23262F;
  --pLine3: #121212;
  --pLine4: #777D90;
}