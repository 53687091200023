@import './colorPack/h5Colors.scss';
@import './colorPack/PCColors.scss';

body,
html {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans,
    Helvetica Neue, sans-serif;
}

button {
  all: unset;
  cursor: pointer;
}

label {
  padding: 0;
  margin-right: 3px;
  cursor: pointer;
}

a {
  color: inherit;
  text-decoration: none;
}

.slick-dots li {
  color: #777e90;

  &.slick-active {
    color: #fff !important;
  }
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }

  body {
    color: white;
    background: black;
  }
}
