:root {
  --ic-cta-blue: #49CDEE;
  --ic-cyan-blue: #03F3F7;
  --ic-mint-green: #64F18C;
  --ic-mint-shadow: #05B48E;
  --ic-purple: #B853FF;
  --ic-purple-shadow: #8950EC;
  --ic-white: #E7E9EC;
  --ic-white-shadow: #E0E5EB;
  
  --sg-grey1: #7C7E87;
  --sg-grey2: #272931;
  --sg-grey3: #0D0D0D;
  --sg-greydl: #3C3E41;
  --sg-greyb: #181920;
  --sg-greyc: #020410;

  --l-primary: #01C3FD;
  --l-default: #E7E9EC;
  --l-disabled: #272931;
  --l-success: #5ABA64;
  --l-error: #FF5555;
  --l-link: #49CDEE;
  --l-white: #E7E9EC;
  --l-black: #000000;
  --l-raise: #01A4FF;
  --l-fall: #FF5555;

  --bg-primary: #0A0C19;
  --bg-gradient1: linear-gradient(90deg, #48CDEEF0 0%, #42ABF8F0 16%, #136CADF0 33%, #013F6CF0 61%, #0A0C19F0 100%);
  --bg-gradient2: linear-gradient(90deg, #A1B8F5F0 0%, #4465B3F0 15%, #002882F0 39%, #07153BF0 65%, #0A0C19F0 100%);
  --bg-gradient3: linear-gradient(90deg, #42ABF8 0%, #864FEB 21%, #0D1952 61%, #0A0C19 100%);
  --bg-glass: linear-gradient(90deg, #000000 0%, #000000 21%, #030305 61%, #0A0C19 100%);

  --o-darker60: rgba(0, 0, 0, 0.6);
  --o-cyan-gradient: linear-gradient(270deg, #48CDEE00 0%, #48CDEE 100%);
  --o-purple-gradient: linear-gradient(90deg, #9548EE 0%, #9548EE00 100%);
  --o-mint-glow: #64F18C;
  --o-cyan-glow: radial-gradient(closest-side at 50% 50%, #48CDEE 0%, #48CDEE 0%, #48CDEE00 100%);
  --o-glowgradient: linear-gradient(271deg, #0A0C1900 0%, #0A0C19B3 67%, #0A0C19 100%);
  --o-scrimMultiply60: linear-gradient(180deg, #171717 0%, #171717 100%);

  --event-gold: #F4CA70;
  --event-blue: #96D8F5;
}